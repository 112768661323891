import * as React from "react"

function VoodooBond(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={64}
      height={64}
      viewBox="0 0 54.646 49.294"
      {...props}
    >
      <path
        d="M30.579 38.253c-5.923 0-10.724-4.8-10.724-10.723 0-3.632 1.807-6.84 4.569-8.78-4.644 1.178-8.079 5.385-8.079 10.394 0 5.923 4.801 10.724 10.724 10.724 2.127 0 4.108-.622 5.776-1.69-.17-.02-.338-.043-.506-.071a10.77 10.77 0 01-1.76.146"
        fill="#fbae17"
      />
      <path
        d="M19.855 27.53c0 5.922 4.8 10.723 10.723 10.723.6 0 1.188-.052 1.761-.146-5.084-.84-8.963-5.255-8.963-10.577 0-3.796 1.973-7.128 4.948-9.034a10.736 10.736 0 00-3.9.254 10.709 10.709 0 00-4.57 8.78"
        fill="#ffbe47"
      />
      <path
        d="M41.79 24.647a2.193 2.193 0 11-4.386 0 2.193 2.193 0 014.385 0"
        fill="#fbae17"
      />
      <path
        d="M50.584 43.816a3.86 3.86 0 01-3.377 1.95H7.439a3.86 3.86 0 01-3.377-1.95 3.86 3.86 0 010-3.9L23.946 5.478a3.859 3.859 0 013.376-1.95 3.86 3.86 0 013.378 1.95l4.802 8.317-4.907 8.499a7.351 7.351 0 000 7.427 7.352 7.352 0 006.432 3.714h9.814l3.743 6.483a3.86 3.86 0 010 3.9m-8.249-30.518a3.828 3.828 0 013.159 1.942l5.09 8.816a3.827 3.827 0 01.103 3.707 3.87 3.87 0 01-3.038 2.115 4.212 4.212 0 01-.442.027h-10.18a3.86 3.86 0 01-3.377-1.95 3.86 3.86 0 010-3.9l5.09-8.814a4.12 4.12 0 01.244-.37l.001-.002a3.872 3.872 0 013.35-1.571M53.64 38.153l-3.162-5.477a7.421 7.421 0 003.355-3.315 7.392 7.392 0 00-.193-7.068l-5.09-8.815a7.393 7.393 0 00-6.024-3.702 7.437 7.437 0 00-4.549 1.249l-4.221-7.312A7.351 7.351 0 0027.322 0a7.35 7.35 0 00-6.431 3.713L1.007 38.153a7.352 7.352 0 000 7.428 7.352 7.352 0 006.432 3.713h39.768a7.351 7.351 0 006.432-3.714 7.351 7.351 0 000-7.427"
        fill="#ffbe47"
      />
    </svg>
  )
}

export default VoodooBond
