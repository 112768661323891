import * as React from "react"

function Wallet(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21.333}
      height={21.333}
      viewBox="0 0 21.333 21.333"
      {...props}
    >
      <path
        d="M.762 3.81zm0 13.714zm16.762 3.048zm-13.714 0zM20.571 3.81zm0 13.714zM17.524.762zM3.81.762zM10.667 9.9zm0 1.524zm9.9 3.048v.762a.762.762 0 00.762-.762zm-6.857 0zm6.857-7.619h.762V6.1h-.762zm-6.857 0zm.762 3.81v1.524A1.524 1.524 0 0016 10.667zm0 0h-1.52a1.524 1.524 0 001.524 1.524zm0 0v-1.52a1.524 1.524 0 00-1.524 1.524zm0 0H16a1.524 1.524 0 00-1.524-1.524zM0 3.81v13.714h1.524V3.81zm17.524 16H3.81v1.524h13.714zm2.286-16v13.714h1.524V3.81zM17.524 0H3.81v1.524h13.714zm3.81 3.81A3.81 3.81 0 0017.524 0v1.524A2.286 2.286 0 0119.81 3.81zm-19.81 0A2.286 2.286 0 013.81 1.524V0A3.81 3.81 0 000 3.81zM0 17.524a3.81 3.81 0 003.81 3.81V19.81a2.286 2.286 0 01-2.286-2.286zm17.524 3.81a3.81 3.81 0 003.81-3.81H19.81a2.286 2.286 0 01-2.286 2.286zM9.9 9.9v1.524h1.524V9.9zm10.667 3.81h-6.853v1.524h6.857zm-.762-6.857v7.619h1.524V6.857zm.762-.762h-6.853v1.524h6.857zM11.429 9.9a2.286 2.286 0 012.286-2.286V6.1A3.81 3.81 0 009.9 9.9zM9.9 11.429a3.81 3.81 0 003.81 3.81v-1.525a2.286 2.286 0 01-2.286-2.286z"
        fill="#fff"
      />
    </svg>
  )
}

export default Wallet
